const appName = (() => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return 'JamBox Dev'
    case 'test':
      return 'JamBox Testing'
    default:
      return 'JamBox'
  }
})()

export const translation = {
  OK: 'OK',
  JamBox: appName,
  No: 'No',
  or: 'or',
  Settings: 'Settings',
  Yes: 'Yes',
  Save: 'Save',
  Paid: 'Paid',
  Back: 'Back',
  'You just received an email to connect':
    'You just received an email to connect',
  'Connect with Google': 'Connect with Google',
  Login: 'Login',
  'My email…': 'My email…',
  'Login with Spotify': 'Login with Spotify',
  'Saved with success!🍺': 'Saved with success!🍺',
  'Pub’s name': 'Pub’s name',
  'You just received {{totalTokens}} tokens! 🔥':
    'You just received {{totalTokens}} tokens! 🔥',
  'A problem happened during payment': 'A problem happened during payment',
  'Buy musics': 'Buy musics',
  'Pay With Lydia': 'Pay With Lydia',
  'You have received a payment request, directly on your Lydia app! Accept it and come back here right after!':
    'You have received a payment request, directly on your Lydia app! Accept it and come back here right after!',
  "It's not working, I want to retry": "It's not working, I want to retry",
  Pay: 'Pay',
  '{{amount}} tokens for JamBox': '{{amount}} tokens for JamBox',
  'Pay With credit card': 'Pay With credit card',
  'The pub doesn’t have any jukebox yet 😢':
    'The pub doesn’t have any jukebox yet 😢',
  'Delete music?': 'Delete music?',
  "The vote didn't work…": "The vote didn't work…",
  'A network error occurred. Please retry later.':
    'A network error occurred. Please retry later.',
  'Back to playlist': 'Back to playlist',
  'Artists, musics…': 'Artists, musics…',
  'Music added! 💃': 'Music added! 💃',
  'An error happened': 'An error happened',
  'Look for the music of your choice': 'Look for the music of your choice',
  'If you add it, it will be the next song to be played!':
    'If you add it, it will be the next song to be played!',
  'Easy as ABC!': 'Easy as ABC!',
  'Add whole playlist': 'Add whole playlist',
  'Adding…': 'Adding…',
  'Add a music': 'Add a music',
}
