import { Effect } from '@/store/types'
import { User } from '@/types/user'

export const updateUser = (user: User | null): Effect<void> => {
  return async dispatch => {
    if (user && typeof window !== 'undefined') {
      window.localStorage.setItem('currentUser', JSON.stringify(user))
    }
    dispatch({ type: 'settings/UPDATE_USER', user })
  }
}

export const hydrateStore: Effect<void> = async dispatch => {
  if (typeof window !== 'undefined') {
    const item = window.localStorage.getItem('currentUser')
    const user = item ? JSON.parse(item) : undefined
    dispatch(updateUser(user))
  }
}
