import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import * as settings from '@/store/reducers/settings'
import * as music from '@/store/reducers/music'
import { hydrateStore } from '@/store/actions/settings'

const reducer = combineReducers({
  settings: settings.reducer,
  music: music.reducer,
})

const composer = composeWithDevTools(applyMiddleware(thunk))
export const store = createStore(reducer, composer)
// @ts-ignore
store.dispatch(hydrateStore)
