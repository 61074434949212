import { Reducer } from 'redux'
import { User } from '@/types/user'

export type State = {
  user: User | null
}

const init: State = {
  user: null,
}

export type Action = { type: 'settings/UPDATE_USER'; user: User | null }

export const reducer: Reducer<State, Action> = (state = init, action) => {
  switch (action.type) {
    case 'settings/UPDATE_USER': {
      const { user } = action
      return { ...state, user }
    }
    default: {
      return state
    }
  }
}
