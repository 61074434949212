const appName = (() => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return 'JamBox Dev'
    case 'test':
      return 'JamBox Testing'
    default:
      return 'JamBox'
  }
})()

export const translation = {
  OK: 'OK',
  JamBox: appName,
  No: 'Non',
  or: 'ou',
  Settings: 'Paramètres',
  Yes: 'Oui',
  Save: 'Enregistrer',
  Paid: 'Payé',
  Back: 'Retour',
  'You just received an email to connect':
    'Tu viens de recevoir un email pour te connecter',
  'Connect with Google': 'Se connecter avec Google',
  Login: "S'enregistrer",
  'My email…': 'Mon email…',
  'Login with Spotify': 'Se connecter à Spotify',
  'Saved with success!🍺': 'Enregistré avec succès ! 🍺',
  'Pub’s name': 'Nom du bar',
  'You just received {{totalTokens}} tokens! 🔥':
    'Tu viens de recevoir {{totalTokens}} jetons ! 🔥',
  'A problem happened during payment':
    'Un problème est survenu lors du paiement',
  'Buy musics': 'Achète des musiques',
  'Pay With Lydia': 'Payer Avec Lydia',
  'You have received a payment request, directly on your Lydia app! Accept it and come back here right after!':
    'Tu as reçu une demande de paiement, directement dans ton application Lydia ! Accepte-là et reviens ici juste après !',
  "It's not working, I want to retry": 'Ça ne marche pas, je veux réessayer',
  Pay: 'Payer',
  '{{amount}} tokens for JamBox': '{{amount}} jetons pour JamBox',
  'Pay With credit card': 'Payer avec une carte bleue',
  'The pub doesn’t have any jukebox yet 😢':
    'Le bar ne propose pas de Jukebox pour le moment 😢',
  'Delete music?': 'Supprimer cette musique ?',
  "The vote didn't work…": "Le vote n'a pas pu être pris en compte…",
  'A network error occurred. Please retry later.':
    'Une erreur de réseau est apparu. Réessayez plus tard.',
  'Back to playlist': 'Retour à la playlist',
  'Artists, musics…': 'Artistes, musiques…',
  'Music added! 💃': 'Musique ajoutée ! 💃',
  'An error happened': 'Une erreur est survenue',
  'Look for the music of your choice': 'Cherchez la musique de votre choix',
  'If you add it, it will be the next song to be played!':
    "Si vous l'ajoutez, elle sera la prochaine à être jouée !",
  'Easy as ABC!': 'Easy as ABC !',
  'Add whole playlist': 'Ajouter toute la playlist',
  'Adding…': 'Ajout en cours…',
  'Add a music': 'Ajouter une musique',
}
