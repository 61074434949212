import { Reducer } from 'redux'
import { Room, UpVote, PaidTrack, Track } from '@/types/room'

export type Songs = {
  [key: string]: ArrayBuffer
}

export type State = {
  room: Room | null
  upVotes: UpVote[]
  paidTracks: PaidTrack[]
  tracks: Track[]
  removedTracks: number[]
  buffers: Songs
}

const init: State = {
  room: null,
  upVotes: [],
  paidTracks: [],
  tracks: [],
  removedTracks: [],
  buffers: {},
}

export type Action =
  | { type: 'music/UPDATE_ROOM'; room: Room | null }
  | { type: 'music/UPDATE_UPVOTES'; upVotes: UpVote[] }
  | { type: 'music/DELETE_UP_VOTE'; vote: UpVote }
  | { type: 'music/ADD_UP_VOTE'; vote: UpVote }
  | { type: 'music/UPDATE_PAID_TRACKS'; paidTracks: PaidTrack[] }
  | { type: 'music/UPDATE_TRACKS'; tracks: Track[]; buffers?: Songs }
  | { type: 'music/REMOVE_TRACK'; track: Track }
  | { type: 'music/ADD_SINGLE_TRACK'; track: Track }

const findVote = (received: UpVote) => (old: UpVote) => {
  const isSameTrackId = received.trackId === old.trackId
  const isSameOwnerId = received.ownerId === old.ownerId
  return isSameTrackId && isSameOwnerId
}

export const reducer: Reducer<State, Action> = (state = init, action) => {
  switch (action.type) {
    case 'music/UPDATE_ROOM': {
      const { room } = action
      return { ...state, room }
    }
    case 'music/UPDATE_UPVOTES': {
      const { upVotes } = action
      return { ...state, upVotes }
    }
    case 'music/UPDATE_PAID_TRACKS': {
      const { paidTracks } = action
      return { ...state, paidTracks }
    }
    case 'music/UPDATE_TRACKS': {
      const byRemovedTracks = (t: Track) => !state.removedTracks.includes(t.id)
      const tracks = action.tracks.filter(byRemovedTracks)
      const buffers = action.buffers ?? state.buffers
      return { ...state, tracks, buffers }
    }
    case 'music/DELETE_UP_VOTE': {
      const { vote } = action
      const index = state.upVotes.findIndex(findVote(vote))
      if (index !== -1) {
        const upVotes = [...state.upVotes]
        upVotes.splice(index, 1)
        return { ...state, upVotes }
      }
      return state
    }
    case 'music/ADD_UP_VOTE': {
      const { vote } = action
      const index = state.upVotes.findIndex(findVote(vote))
      if (index === -1) {
        const upVotes = [...state.upVotes, vote]
        return { ...state, upVotes }
      }
      return state
    }
    case 'music/REMOVE_TRACK': {
      const { track } = action
      const { id } = track
      const removedTracks = [...state.removedTracks, id]
      const tracks = state.tracks.filter(t => t.id !== id)
      return { ...state, removedTracks, tracks }
    }
    case 'music/ADD_SINGLE_TRACK': {
      const { track } = action
      const index = state.tracks.findIndex(t => t.id === track.id)
      if (index >= 0) return state
      const tracks = [...state.tracks, track]
      return { ...state, tracks }
    }
    default: {
      return state
    }
  }
}
