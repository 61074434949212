import 'focus-visible/dist/focus-visible'
import React, { FC, Fragment } from 'react'
import Head from 'next/head'
import { ChakraProvider } from '@chakra-ui/react'
import { Provider } from 'react-redux'
import { AppProps } from 'next/dist/shared/lib/router/router'
import { store } from '@/store'
import theme from '@/theme'
import '@/services/i18n'
import '@/assets/styles/index.css'
import '@/assets/styles/Loading.css'
import '@/assets/styles/Player.css'

const VIEWPORT = 'width=device-width, initial-scale=1.0, viewport-fit=cover'
const Header = () => (
  <Head>
    <title>Jamhouse - Revive the Jukebox</title>
    <meta name="viewport" content={VIEWPORT} />
  </Head>
)

const App: FC<AppProps> = ({ Component, pageProps }) => (
  <Fragment>
    <Header />
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <Component {...pageProps} />
      </ChakraProvider>
    </Provider>
  </Fragment>
)

export default App
