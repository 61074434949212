import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import * as en from '@/translations/en'
import * as fr from '@/translations/fr'

i18n.use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: 'en',
  resources: { en, fr },
  keySeparator: false,
})

export const changeLanguage = async (locale: string) => {
  return await i18n.changeLanguage(locale)
}

export default i18n
